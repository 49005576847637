<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <form class="form fv-plugins-bootstrap fv-plugins-framework" id="dataExpressesForm" novalidate="novalidate">


                    <div class="card card-custom example example-compact">
                        <div class="card-header">
                            <h3 class="card-title">物流信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">物流渠道 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <treeselect
                                            :options="expresses_channel_list"
                                            :default-expand-level="1"
                                            placeholder="请选择物流渠道"
                                            v-model="order.expresses.channel"
                                    />
                                    <input type="hidden" class="form-control" v-model="order.expresses.channel" name="expresses_channel"/>
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">收件人 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="username"
                                           v-model="order.expresses.username">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">联系电话 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="phone"
                                           v-model="order.expresses.phone">
                                </div>
                            </div>
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">邮编 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="postcode"
                                           v-model="order.expresses.postcode">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">国家 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="country"
                                           v-model="order.expresses.country">
                                </div>
                            </div>
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">州/省/郡 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="province"
                                           v-model="order.expresses.province">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">城市 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="city"
                                           v-model="order.expresses.city">
                                </div>
                            </div>
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">详细地址 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <textarea class="form-control tt-input" v-model="order.expresses.address" name="address"></textarea>
                                </div>
                            </div>


                        </div>
                    </div>
                    <!--end::Card-->

                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">报关信息</h3>
                        </div>
                        <div class="card-body">

                                <table class="table table-vertical-center">
                                    <thead>
                                    <tr class="alert alert-light">
                                        <th scope="col">产品信息</th>
                                        <th scope="col" width="300px">产品中文/英文名报关</th>
                                        <th scope="col" width="150px">包裹重量（g）</th>
                                        <th scope="col" width="150px">货物总数量</th>
                                        <th scope="col" width="150px">申报总价值</th>
                                        <th scope="col" width="150px">申报币种</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(item, idx) in order.declaration">
                                        <tr :key="idx">
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="symbol symbol-60 flex-shrink-0">
                                                        <div class="symbol-label"
                                                             :style="'background-image:url('+item.image_url+')'"></div>
                                                    </div>
                                                    <div class="ml-2">
                                                        <div class="text-dark-75 font-weight-bold line-height">
                                                            {{item.product_title}}
                                                        </div>
                                                        <div class="text-dark-75 line-height">
                                                            {{item.sku}}
                                                        </div>
                                                        <div class="font-size-sm text-dark-50 text-hover-primary">
                                                            <template v-for="attr in JSON.parse(item.attr_info)">
                                                                <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group row fv-plugins-icon-container pr-5 pl-5">
                                                    <input class="form-control tt-input"
                                                           type="text"
                                                           name="chinese_declaration"
                                                           v-model="order.declaration[idx].chinese_declaration">
                                                </div>
                                                <div class="form-group row fv-plugins-icon-container  pr-5 pl-5">
                                                    <input class="form-control tt-input"
                                                           type="text"
                                                           v-model="order.declaration[idx].english_declaration"
                                                           name="english_declaration"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group row fv-plugins-icon-container  pr-5 pl-5">
                                                    <input class="form-control tt-input"
                                                           @input="onFloatInput"
                                                           v-model="order.declaration[idx].declaration_weight"
                                                           name="declaration_weight"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group row fv-plugins-icon-container  pr-5 pl-5">
                                                    <input class="form-control tt-input"
                                                           @input="onIntInput"
                                                           v-model="order.declaration[idx].quantity"
                                                           name="quantity"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group row fv-plugins-icon-container  pr-5 pl-5">
                                                    <input class="form-control tt-input"
                                                           @input="onFloatInput"
                                                           v-model="order.declaration[idx].declaration_price"
                                                           name="declaration_price"/>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group row fv-plugins-icon-container pr-5 pl-5">
                                                    <select class="form-control tt-input"
                                                            v-model="order.declaration[idx].currency"
                                                            name="currency">
                                                        <template v-for="(c, idx) in currency_list">
                                                            <option :key="idx" :value="c.code">{{c.code}}</option>
                                                        </template>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                        </div>
                        <div class="card-footer">
                            <button ref="data_form_submit" class="btn btn-primary mr-2" type="button" @click="saveForm()">保存</button>
                            <a href="/order" class="btn btn-secondary" type="reset">返回</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <v-dialog v-model="dialog.show" persistent max-width="700px">
            <component
                    :key="dialog.id"
                    :title="dialog.title"
                    :visible="dialog.show"
                    :is="dialog.view"
                    :dialog_data="dialog.data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

    </v-app>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTUtil from "@/assets/js/components/util";
    import TEditor from '@/view/content/tinymce/Index'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'
    import {getOrder, getCurrency, createExpresses, getDeclaration, getExpressesChannel} from '@/api/order';


    export default {
        name: "show_order",
        components: {
            KTSubheader,
            TEditor,
            Treeselect
        },
        created: function(){
            var _this = this;
            getCurrency({}).then(function(res) {
                if(res && res.response) {
                    _this.currency_list = res.response;
                }
            });
            getOrder({'order_id': _this.$route.query.id}).then(function(res) {
                if(res && res.response) {
                    var address = res.response.address;
                    _this.order = res.response;
                    _this.order['expresses_type'] = '1';
                    _this.$set(_this.order, 'expresses', {
                        'channel': null,
                        'username' : address.username,
                        'phone' : address.phone,
                        'postcode' : address.postcode,
                        'country_code': address.country_code,
                        'country': _this.getCountryInfo(address.country_code),
                        'province': address.province,
                        'city': address.city,
                        'address': address.address
                    });

                    //查询报关信息
                    getDeclaration({'order_id': _this.$route.query.id}).then(function(res) {
                        if(res && res.response) {
                            _this.$set(_this.order, 'declaration', res.response);
                        }
                    });

                    //获取物流渠道
                    getExpressesChannel({'expresses_type': 1, 'country_code': address.country_code}).then(function(res) {
                        if(res && res.response) {
                            var options = [];
                            res.response.channel.forEach(function(val){
                                options.push({'id': val.Id, 'label': val.Name});
                            });

                            _this.expresses_channel_list = options;

                        }
                    });
                }
            });
        },
        watch: {

        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/order', 'title':'订单管理'}, {'id':'', 'route':'/order/expressesorder', 'title':'创建物流单'}],
                    pageTitle: '订单'
                },
                currency_list: [],
                expresses_channel_list: [],
                order: {
                    id: '',
                    platform_id: '',
                    site_id: '',
                    product_id: null,
                    product_title: '',
                    details: [],
                    currency: 'USD',
                    origin_base: '',
                    total_amount: '',
                    fee: '',
                    freight: '',
                    coupon: '',
                    tax: '',
                    amount: '',
                    channel: '',
                    pay_account: '',
                    pay_amount: '',
                    get_account: '',
                    user_account: '',
                    ip: '',
                    country: '',
                    contact_email: '',
                    expresses_type: '1',
                    address: {
                        username: '',
                        country_code: null,
                        province: '',
                        city: '',
                        postcode: '',
                        phone: '',
                        address: ''
                    },
                    note: '',
                    expresses: {
                        channel: null
                    },
                    declaration: []
                },

                dialog: {
                    id: 0,
                    show: false,
                    title: '',
                    view: null,
                    data: null
                }

            }
        },
        mounted() {

        },
        methods: {

            opeareResultHandler(result) {
                var _this = this;
                if(result && result.length > 0){
                    result.forEach(function(value){
                        _this.order.details.push(value);
                    });

                }
                this.dialog.show = false;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(e){

                e.target.value = parseInt(e.target.value) || null;
            },
            loadOptions({ action, searchQuery, callback }) {
                if (action === 'ASYNC_SEARCH' && this.order.site_id) {
                    getProduct({'product_title': searchQuery, 'site_id': this.order.site_id}).then(function (res) {
                        if(res && res.response){
                            var options = [];
                            res.response.forEach(function(value){
                                options.push({'id': value.id, 'label': value.site_name +'  '+ value.product_title});
                            });
                            callback(null, options);
                        }
                    });
                } else {
                    KTUtil.toast(this, '操作提醒', '请选择站点', 'danger');
                    return;
                }

            },

            selectProduct(val){
                this.order.product_title = val.label;
            },
            addProductSku() {
                if (!this.order.product_id) {
                    KTUtil.toast(this, '操作提醒', '请选择产品', 'danger');
                    return;
                }
                this.dialog.id = new Date().getTime();
                this.dialog.show = true;
                this.dialog.title = '添加 '+ this.order.product_title +' 产品SKU';
                this.dialog.view = addProductSku;
                this.dialog.data = {
                    product_id: this.order.product_id,
                    product_title: this.order.product_title
                }
            },
            calculateAmount() {
                var _this = this;
                var amount = 0;

                //获取汇率
                if(_this.order.currency){
                    _this.currency_list.forEach(function(value){
                        if(value.code == _this.order.currency){
                            _this.order.origin_base = value.currency;
                            return true;
                        }
                    });
                }


                _this.order.details.forEach(function(value, index){
                    var sum = value.quantity * value.price * _this.order.origin_base;
                    if(sum) {
                        amount += sum;
                        _this.order.details[index].amount = sum.toFixed(2);
                    }
                });
                _this.order.total_amount = amount?amount.toFixed(2):'';

                if(_this.order.fee) {
                    amount += parseFloat(_this.order.fee);
                }
                if(_this.order.coupon) {
                    amount -= parseFloat(_this.order.coupon);
                }
                if(_this.order.tax) {
                    amount += parseFloat(_this.order.tax);
                }
                if(_this.order.freight) {
                    amount += parseFloat(_this.order.freight);
                }
                _this.order.pay_amount = amount?amount.toFixed(2):'';
                _this.order.amount = amount?amount.toFixed(2):'';
            },
            deleteProductSku(index) {
                this.order.details.splice(index, 1);
            },
            back() {
                this.$router.push({path: '/order'});
            },
            getOrderStatus(status) {
                if(status == 0){
                    return '未付款'
                } else if(status == 1) {
                    return '已付款'
                } else if(status == 2) {
                    return '创建物流单'
                } else if(status == 3) {
                    return '已发货'
                } else if(status == 4) {
                    return '已完成'
                } else if(status == 5) {
                    return '已撤销'
                } else if(status == 6) {
                    return '已退款'
                } else if(status == 7) {
                    return '问题单'
                }
            },
            getCountryInfo(code) {
                var html = ''
                var country_list = KTUtil.getCountryList();
                country_list.forEach(function(value) {
                    if(value.locale == code) {
                        html = value.en;
                        return false;
                    }
                });
                return html;
            },
            saveForm() {

                var data_form = KTUtil.getById("dataExpressesForm");

                //表单数据验证
                this.fv = formValidation(data_form, {
                    fields: {
                        expresses_channel: {
                            validators: {
                                notEmpty: {
                                    message: "请选择物流渠道"
                                }
                            }
                        },
                        username: {
                            validators: {
                                notEmpty: {
                                    message: "请输入收件人"
                                }
                            }
                        },
                        phone: {
                            validators: {
                                notEmpty: {
                                    message: "请输入收件人联系电话"
                                }
                            }
                        },
                        postcode: {
                            validators: {
                                notEmpty: {
                                    message: "请输入邮编"
                                }
                            }
                        },
                        country: {
                            validators: {
                                notEmpty: {
                                    message: "请输入国家"
                                }
                            }
                        },
                        province: {
                            validators: {
                                notEmpty: {
                                    message: "请输入州/省/郡"
                                }
                            }
                        },
                        city: {
                            validators: {
                                notEmpty: {
                                    message: "请输入城市"
                                }
                            }
                        },
                        address: {
                            validators: {
                                notEmpty: {
                                    message: "请输入详细地址"
                                }
                            }
                        },
                        chinese_declaration: {
                            validators: {
                                notEmpty: {
                                    message: "请输入中文报关"
                                }
                            }
                        },
                        english_declaration: {
                            validators: {
                                notEmpty: {
                                    message: "请输入英文报关"
                                }
                            }
                        },
                        declaration_weight: {
                            validators: {
                                notEmpty: {
                                    message: "请输入报关包裹重量"
                                },
                                callback: {
                                    message: "包裹重量需大于0",
                                    callback: function(value, validator) {
                                        if(value.value > 0) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                }
                            }
                        },
                        quantity: {
                            validators: {
                                notEmpty: {
                                    message: "请输入报关货物数量"
                                },
                                callback: {
                                    message: "物数量需大于0",
                                    callback: function(value, validator) {
                                        if(value.value > 0) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                }
                            }
                        },
                        declaration_price: {
                            validators: {
                                notEmpty: {
                                    message: "请输入报关货物价值"
                                },
                                callback: {
                                    message: "报关货物需大于0",
                                    callback: function(value, validator) {
                                        if(value.value > 0) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                }
                            }
                        },
                        currency: {
                            validators: {
                                notEmpty: {
                                    message: "请选择申报币种"
                                }
                            }
                        },

                    },
                    plugins: {
                        trigger: new Trigger(),
                        submitButton: new SubmitButton(),
                        bootstrap: new Bootstrap()
                    }
                });


                this.fv.on("core.form.valid", () => {
                    var _this = this;

                    //按钮加载动画
                    const submitButton = this.$refs["data_form_submit"];
                    submitButton.classList.add("spinner", "spinner-light", "spinner-right");
                    createExpresses(_this.order).then(function (res){
                        if (res) {
                            KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                            data_form.reset();
                            _this.$router.push({path: '/order'});
                        }
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                    });
                });
                this.fv.validate();

            }
        },

    }
</script>
